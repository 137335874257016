
import { defineComponent, inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { AxiosError } from "axios";

// services
import projectService from "@/services/project.service";
import testService from "@/services/test.service";

// components
import AssessmentList from "@/components/molecules/AssessmentList.molecules.vue";

// interfaces
import { IAssessment } from "@/interfaces/assessment";

export default defineComponent({
  components: {
    AssessmentList,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const setPageTitle = inject("setPageTitle") as CallableFunction;
    onMounted(() => setPageTitle("Edit Proyek"));

    // select assessment
    const selectedAssessment = ref<number[]>([]);
    const setSelectedAssessment = (val: number[]) => {
      selectedAssessment.value = val;
    };

    // get assessments list
    let assessments = ref<IAssessment[]>([]);
    onMounted(async () => {
      const { data } = await testService.getTests();
      assessments.value = data;
    });

    // handle form
    const projectName = ref<string>("");
    const startedAt = ref<string>("");
    const endedAt = ref<string>("");

    // get assessment's project
    onMounted(async () => {
      const projectId = Number(route.params.projectId);

      const { data } = await projectService.getProject(projectId);
      selectedAssessment.value = data.tests.map((d: IAssessment) => d.id);

      projectName.value = data.name;
      startedAt.value = data.started_at;
      endedAt.value = data.ended_at;
    });

    const createProject = async () => {
      try {
        const req = {
          name: projectName.value,
          started_at: startedAt.value,
          ended_at: endedAt.value,
          test_id: selectedAssessment.value,
        };

        await projectService.createProject(req);
        toast.success("Berhasil disimpan");
        router.push("/admin/home");
      } catch (e) {
        let errMsg = (e as AxiosError).response?.data?.error || "Ada kesalahan";
        toast.error(errMsg);
      }
    };
    return {
      assessments,
      projectName,
      startedAt,
      endedAt,
      createProject,
      selectedAssessment,
      setSelectedAssessment,
    };
  },
});
